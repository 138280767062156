import { gql } from "@utils/StringUtils";

class Fragments {
    /// PRODUCTS

    public static readonly productPrint = gql`
        fragment ProductPrint on ProductPrint {
            id
            gift_limit
            image
            discount_price
            is_badge_discount
            is_renewable
            name
            publication {
                id
                short_name
                image
            }
            quiz_required {
                id
            }
            renew_period_month_discounted
            susperiod
            text_featured
            text_gift
            text_more
            type_info
            external_url
            standard_price
            monthly_standard_price
            price {
                unit_price
            }
            current_subscription_end
            is_public
            fix_subscription_start_date
        }
    `;

    public static readonly productPrintIssue = gql`
        fragment ProductPrintIssue on ProductPrintIssue {
            gift_limit
            id
            image
            discount_price
            is_badge_discount
            issue_nr
            name
            price {
                unit_price
            }
            publication {
                id
                short_name
                image
            }
            text_featured
            text_gift
            text_more
            type_info
            external_url
            price {
                unit_price
            }
            current_subscription_end
            is_public
            fix_subscription_start_date
        }
    `;

    public static readonly productDigital = gql`
        fragment ProductDigital on ProductDigital {
            gift_limit
            id
            image
            discount_price
            is_badge_discount
            issue_nr
            name
            publication {
                id
                short_name
                image
            }
            text_featured
            text_gift
            text_more
            type_info
            external_url
            price {
                unit_price
            }
            current_subscription_end
            is_public
            fix_subscription_start_date
        }
    `;

    public static readonly productService = gql`
        fragment ProductService on ProductService {
            gift_limit
            id
            image
            discount_price
            is_badge_discount
            name
            publication {
                id
                short_name
                image
            }
            text_featured
            text_gift
            text_more
            type_info
            external_url
            price {
                unit_price
            }
            current_subscription_end
            is_public
            fix_subscription_start_date
        }
    `;

    public static readonly productBundle = gql`
        fragment ProductBundle on ProductBundle {
            id
            gift_limit
            image
            discount_price
            is_badge_discount
            name
            price {
                unit_price
            }
            products {
                ... on ProductPrint {
                    ...ProductPrint
                }
                ... on ProductPrintIssue {
                    ...ProductPrintIssue
                }
                ... on ProductDigital {
                    ...ProductDigital
                }
                ... on ProductService {
                    ...ProductService
                }
                __typename
            }
            bundlePublication: publication {
                id
                short_name
                image
            }
            text_featured
            text_gift
            text_more
            type_info
            external_url
            price {
                unit_price
            }
            current_subscription_end
            is_public
            fix_subscription_start_date
            has_more_addresses
            __typename
        }
        ${Fragments.productPrint}
        ${Fragments.productPrintIssue}
        ${Fragments.productDigital}
        ${Fragments.productService}
    `;

    public static readonly productDigitalService = gql`
        fragment ProductDigitalService on ProductDigitalService {
            gift_limit
            id
            image
            discount_price
            is_badge_discount
            name
            publication {
                id
                short_name
                image
            }
            text_featured
            text_gift
            text_more
            type_info
            external_url
            price {
                unit_price
            }
            current_subscription_end
            is_public
            fix_subscription_start_date
        }
    `;

    public static readonly productOrder = gql`
        fragment ProductOrder on ProductOrder {
            id
            am_code
            name
            slug
            external_url
            image
            type_info
            text_featured
            text_more
            text_gift
            marketing_code
            discount_price
            susperiod
            is_visible
            price {
                unit_price
            }
        }
    `;

    public static readonly anyProduct = gql`
        fragment AnyProduct on ProductInterface {
            ... on ProductPrint {
                ...ProductPrint
            }
            ... on ProductBundle {
                ...ProductBundle
            }
            ... on ProductPrintIssue {
                ...ProductPrintIssue
            }
            ... on ProductDigital {
                ...ProductDigital
            }
            ... on ProductService {
                ...ProductService
            }
            ... on ProductDigitalService {
                ...ProductDigitalService
            }
            __typename
        }
        ${Fragments.productPrint}
        ${Fragments.productBundle}
        ${Fragments.productPrintIssue}
        ${Fragments.productDigital}
        ${Fragments.productService}
        ${Fragments.productDigitalService}
    `;

    public static readonly userShippingAddress = gql`
        fragment UserShippingAddress on UserShippingAddress {
            id
            is_company
            company_name
            company_contact
            tax_number
            title
            is_default
            contact_prefix
            contact_firstname
            contact_lastname
            postcode
            city
            street
            public_place_type {
                id
                name
            }
            street_number
            staircase
            floor
            door_bell
            door_number
            other
        }
    `;

    public static readonly userBillingAddress = gql`
        fragment UserBillingAddress on UserBillingAddress {
            id
            is_company
            company_name
            tax_number
            company_contact
            contact_prefix
            contact_firstname
            contact_lastname
            country
            postcode
            city
            street
            public_place_type {
                id
                name
            }
            public_place
            street_number
            staircase
            floor
            door_bell
            door_number
            other
        }
    `;

    public static readonly orderItem = gql`
        fragment OrderItem on OrderItem {
            product {
                ...ProductOrder
            }
            subscription_start
            shipping_is_company
            shipping_company_name
            shipping_company_contact
            shipping_tax_number
            shipping_contact_prefix
            shipping_contact_firstname
            shipping_contact_lastname
            shipping_postcode
            shipping_city
            shipping_street
            shipping_public_place_type {
                name
            }
            shipping_street_number
            shipping_staircase
            shipping_floor
            shipping_door_number
            shipping_door_bell
        }
        ${Fragments.productOrder}
    `;

    public static readonly orderItemBundle = gql`
        fragment OrderItemBundle on OrderItemBundle {
            is_expanded
            orderItems {
                ...OrderItem
            }
            subscription_start
            shipping_is_company
            shipping_company_name
            shipping_company_contact
            shipping_tax_number
            shipping_contact_prefix
            shipping_contact_firstname
            shipping_contact_lastname
            shipping_postcode
            shipping_city
            shipping_street
            shipping_public_place_type {
                name
            }
            shipping_street_number
            shipping_staircase
            shipping_floor
            shipping_door_number
            shipping_door_bell
        }
        ${Fragments.orderItem}
    `;

    public static readonly anyOrderItem = gql`
        fragment AnyOrderItem on OrderItemInterface {
            ... on OrderItem {
                ...OrderItem
                product {
                    publication {
                        id
                        default_product {
                            id
                        }
                    }
                }
            }
            ... on OrderItemBundle {
                ...OrderItemBundle
            }
            __typename
        }
        ${Fragments.orderItem}
        ${Fragments.orderItemBundle}
    `;

    public static readonly paymentMethod = gql`
        fragment PaymentMethod on PaymentMethod {
            id
            name
            type
            description
        }
    `;

    public static readonly order = gql`
        fragment Order on Order {
            id
            order_batch_id
            status
            payment_method {
                ...PaymentMethod
            }
            created_at
            billing_contact_prefix
            billing_contact_firstname
            billing_contact_lastname
            billing_is_company
            billing_company_name
            billing_postcode
            billing_city
            billing_street
            billing_public_place_type {
                name
            }
            billing_street_number
            billing_staircase
            billing_floor
            billing_door_number
            items {
                ...AnyOrderItem
            }
            batch {
                latest_transaction {
                    code
                    status
                }
            }
        }
        ${Fragments.paymentMethod}
        ${Fragments.anyOrderItem}
    `;

    public static readonly orderSubscription = gql`
        fragment OrderSubscription on OrderSubscription {
            id
            order_item_id
            subscription_start
            next_charge_at
            created_at
            stopped_at
            order_item {
                ...AnyOrderItem
                order {
                    id
                    order_batch_id
                }
            }
        }
        ${Fragments.anyOrderItem}
    `;

    public static readonly subscriptionBatch = gql`
        fragment SubscriptionBatch on SubscriptionBatch {
            code
            is_active
            subscription_start
            subscription_end
            lastSubscription {
                ...OrderSubscription
            }
        }
        ${Fragments.orderSubscription}
    `;

    public static readonly user = gql`
        fragment User on User {
            id
            ext_id
            partner_code
            email
            contact_prefix
            contact_firstname
            contact_lastname
            contact_phone
            is_subscribed_to_newsletter
            shipping_addresses {
                ...UserShippingAddress
            }
            billing_address {
                ...UserBillingAddress
            }
            orders {
                ...Order
            }
            offer_ids
        }
        ${Fragments.userShippingAddress}
        ${Fragments.userBillingAddress}
        ${Fragments.order}
    `;

    public static readonly userAuthenticationResult = gql`
        fragment UserAuthenticationResult on UserAuthenticationResult {
            token
            user {
                ...User
            }
        }
        ${Fragments.user}
    `;

    public static readonly cmsContent = gql`
        fragment CmsContent on CmsContent {
            id
            url
            title
            lead
            lead_image
            content
            meta_title
            meta_description
            meta_image
            meta_keywords
        }
    `;

    public static readonly publicPlaceType = gql`
        fragment PublicPlaceType on PublicPlaceType {
            id
            name
        }
    `;

    public static readonly paginatorInfo = gql`
        fragment PaginatorInfo on PaginatorInfo {
            count
            currentPage
            firstItem
            hasMorePages
            lastItem
            lastPage
            perPage
            total
        }
    `;

    public static readonly publication = gql`
        fragment Publication on Publication {
            id
            short_name
            name
            image
            lead
            issues_year
            products {
                ...AnyProduct
            }
            digital_products {
                ...AnyProduct
            }
            default_product {
                ...AnyProduct
            }
            price {
                unit_price
            }
            bundles {
                ...ProductBundle
            }
            is_featured
        }
        ${Fragments.anyProduct}
        ${Fragments.productBundle}
    `;

    public static readonly slider = gql`
        fragment Slider on Slider {
            id
            image_url
            subtitle
            title
            url
            headline
        }
    `;

    public static readonly companyAddress = gql`
        fragment CompanyAddress on CompanyAddress {
            company_name
            tax_number
            postcode
            city
            street
            public_place_type {
                id
                name
            }
            street_number
        }
    `;

    public static readonly cartItemNormal = gql`
        fragment CartItemNormal on CartItemNormal {
            id
            subscription_start
            next_subscription_start
            shipping_address {
                ...UserShippingAddress
            }
            discount_amount
            discounts {
                amount
                title
            }
            price
            price_original
            product {
                ...AnyProduct
            }
            is_recurring
            is_next_subscription_start_overridden
            transfer
            subscription_dates
        }
        ${Fragments.anyProduct}
    `;

    public static readonly cartItemOffer = gql`
        fragment CartItemOffer on CartItemOffer {
            id
            subscription_start
            shipping_address {
                ...UserShippingAddress
            }
            discount_amount
            discount_percent
            discounts {
                amount
                title
            }
            price
            price_original
            product {
                ...AnyProduct
            }
            transfer
            subscription_dates
        }
        ${Fragments.anyProduct}
    `;

    public static readonly cartItem = gql`
        fragment CartItem on CartItem {
            ... on CartItemNormal {
                ...CartItemNormal
            }
            ... on CartItemChristmas {
                id
                subscription_start
                shipping_address {
                    ...UserShippingAddress
                }
                discount_amount
                discounts {
                    amount
                    title
                }
                price
                price_original
                product {
                    ...AnyProduct
                }
                is_next_subscription_start_overridden
                transfer
                subscription_dates
            }
            ... on CartItemBundle {
                id
                cartItems {
                    ...CartItemNormal
                }
                discount_amount
                discounts {
                    amount
                    title
                }
                id
                is_expanded
                price
                price_original
                product {
                    ...AnyProduct
                }
                shipping_address {
                    ...UserShippingAddress
                }
                subscription_start
                is_next_subscription_start_overridden
                transfer
            }
        }
        ${Fragments.userShippingAddress}
        ${Fragments.cartItemNormal}
        ${Fragments.anyProduct}
    `;

    public static readonly anyCartItem = gql`
        fragment AnyCartItem on CartItemInterface {
            ... on CartItemNormal {
                ...CartItemNormal
            }
            ... on CartItemChristmas {
                id
                subscription_start
                shipping_address {
                    ...UserShippingAddress
                }
                discount_amount
                discounts {
                    amount
                    title
                }
                price
                price_original
                product {
                    ...AnyProduct
                }
                transfer
                subscription_dates
            }
            ... on CartItemBundle {
                id
                cartItems {
                    ...CartItemNormal
                }
                discount_amount
                discounts {
                    amount
                    title
                }
                id
                is_expanded
                price
                price_original
                product {
                    ...AnyProduct
                }
                shipping_address {
                    ...UserShippingAddress
                }
                subscription_start
                transfer
            }
            ... on CartItemGratis {
                id
                subscription_start
                shipping_address {
                    ...UserShippingAddress
                }
                discount_amount
                discounts {
                    amount
                    title
                }
                price
                price_original
                product {
                    ...AnyProduct
                }
                transfer
                subscription_dates
            }
            ... on CartItemOffer {
                ...CartItemOffer
            }
            next_subscription_start
            is_next_subscription_start_overridden
            __typename
        }
        ${Fragments.cartItemNormal}
        ${Fragments.cartItemOffer}
        ${Fragments.anyProduct}
        ${Fragments.userShippingAddress}
    `;

    public static readonly cart = gql`
        fragment Cart on Cart {
            type
            items {
                ...AnyCartItem
            }
            discount_total
            grand_total
            sub_total
            messages
        }
        ${Fragments.anyCartItem}
    `;

    public static readonly giftCard = gql`
        fragment GiftCard on GiftCard {
            code
            value
        }
    `;

    public static readonly checkoutCoupon = gql`
        fragment CheckoutCoupon on CheckoutCoupon {
            code
            is_valid
            discount {
                title
                discount_type
            }
            discount_amount
        }
    `;

    public static readonly cartDiscount = gql`
        fragment CartDiscount on CartDiscount {
            title
            amount
            partner_code
        }
    `;

    public static readonly checkout = gql`
        fragment Checkout on Checkout {
            sub_total
            discount_total
            grand_total
            gift_card {
                ...GiftCard
            }
            billing_address {
                ...UserBillingAddress
            }
            payment_method {
                ...PaymentMethod
            }
            require_billing_address
            post_invoice
            user_register {
                ...User
            }
            cart {
                ...Cart
            }
            coupons {
                ...CheckoutCoupon
            }
            discounts {
                ...CartDiscount
            }
            coupon_earned
            is_register
        }
        ${Fragments.giftCard}
        ${Fragments.userBillingAddress}
        ${Fragments.paymentMethod}
        ${Fragments.user}
        ${Fragments.cart}
        ${Fragments.checkoutCoupon}
        ${Fragments.cartDiscount}
    `;

    public static readonly landingNotification = gql`
        fragment LandingNotification on LandingNotification {
            id
            name
            title
            content
            is_accept_required
            is_active
            active_from
            active_to
        }
    `;

    public static readonly opkTransaction = gql`
        fragment OpkTransaction on OpkTransaction {
            code
            status
            simple_transaction_id
            payment_method_type
            success
            error_message
            order_batch_code
            redirect_url
        }
    `;

    public static readonly productSegment = gql`
        fragment ProductSegment on ProductSegment {
            publications {
                ...Publication
            }
            products {
                ...AnyProduct
            }
        }
        ${Fragments.anyProduct}
        ${Fragments.publication}
    `;

    public static readonly discountNormal = gql`
        fragment DiscountNormal on DiscountNormal {
            title
            subtitle
            lead
            slug
            image
            image_background
            url_terms
            cart_total_limit
            active_from
            active_to
            product_segment {
                ...ProductSegment
            }
            description
            top_description
            image_alignment_right
        }
        ${Fragments.productSegment}
    `;

    public static readonly christmasDiscountPrice = gql`
        fragment ChristmasDiscountPrice on ChristmasDiscountPrice {
            price_original
            price
            products
        }
    `;

    public static readonly discountChristmas = gql`
        fragment DiscountChristmas on DiscountChristmas {
            title
            subtitle
            lead
            slug
            image
            image_background
            url_terms
            cart_total_limit
            active_from
            active_to
            product_segment {
                ...ProductSegment
            }
            description
            half_year {
                ...ChristmasDiscountPrice
            }
            year {
                ...ChristmasDiscountPrice
            }
            top_description
        }
        ${Fragments.productSegment}
        ${Fragments.christmasDiscountPrice}
    `;

    public static readonly discount = gql`
        fragment Discount on Discount {
            ... on DiscountNormal {
                ...DiscountNormal
            }
            ... on DiscountChristmas {
                ...DiscountChristmas
            }
            __typename
        }
        ${Fragments.discountNormal}
        ${Fragments.discountChristmas}
    `;

    public static readonly offerInit = gql`
        fragment OfferInit on Offer {
            id
            code
            is_active
            is_visible
            active_from
            active_to
            offerProducts {
                id
                publication {
                    id
                    short_name
                }
                product {
                    ... on ProductInterface {
                        id
                    }
                }
            }
        }
    `;

    public static readonly offer = gql`
        fragment Offer on Offer {
            id
            code
            is_active
            active_from
            active_to
            lead
            offerProducts {
                id
                publication {
                    id
                    short_name
                    image
                }
                product {
                    ...AnyProduct
                }
                price
                original_price
                discount_percent
                vat
            }
        }
        ${Fragments.anyProduct}
    `;

    public static readonly offerDetails = gql`
        fragment OfferDetails on Offer {
            id
            code
            lead
            is_active
            active_from
            active_to
            subscription_start
            email
            contact_prefix
            contact_firstname
            contact_lastname
            contact_phone
            shipping_is_company
            shipping_company_name
            shipping_company_contact
            shipping_tax_number
            shipping_contact_prefix
            shipping_contact_firstname
            shipping_contact_lastname
            shipping_postcode
            shipping_city
            shipping_street
            shipping_public_place_type {
                id
                name
            }
            shipping_street_number
            shipping_staircase
            shipping_floor
            shipping_door_number
            shipping_door_bell
            shipping_other
            billing_is_company
            billing_company_name
            billing_company_contact
            billing_tax_number
            billing_contact_prefix
            billing_contact_firstname
            billing_contact_lastname
            billing_country
            billing_postcode
            billing_city
            billing_street
            billing_public_place_type {
                id
                name
            }
            billing_street_number
            billing_staircase
            billing_floor
            billing_door_number
            billing_other
            offerProducts {
                id
                price
                original_price
                discount_percent
                vat
                product {
                    __typename
                    ... on ProductInterface {
                        id
                    }
                }
                next_transfer_date
                quantity
            }
            paymentMethod {
                id
                name
                type
                description
            }
        }
    `;

    public static readonly periodicSubscription = gql`
        fragment PeriodicSubscription on PeriodicSubscription {
            id
            name
            shipping_name
            shipping_city
            shipping_address
            from
            to
            length
        }
    `;

    public static readonly paymentStatusResult = gql`
        fragment PaymentStatusResult on OpkTransaction {
            code
            status
            simple_transaction_id
            payment_method_type
            success
            error_message
            order_batch_code
            redirect_url
            payment_method_type
            order_batch {
                orders {
                    created_at
                    order_batch_id
                    items {
                        __typename
                        price
                        ... on OrderItem {
                            product {
                                type
                                am_code
                                name
                            }
                        }
                        ... on OrderItemBundle {
                            orderItems {
                                product {
                                    type
                                    am_code
                                    name
                                }
                            }
                        }
                    }
                }
                grand_total
                net_grand_total
                tax_total
                coupons
            }
        }
    `;
}

export { Fragments };
